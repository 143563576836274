import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { SettingsService } from "../../core/settings/settings.service";
import { AuthService } from "@core/auth/auth.service";
import { Subscription } from "rxjs";

@Component({
    selector: "app-login-callback",
    templateUrl: "login-callback.component.html",
    styleUrls: ["./login-callback.component.scss"],
})
export class LoginCallbackComponent implements OnInit, OnDestroy {
    stream: Subscription;
    isRedirecting = false;

    constructor(
        private authService: AuthService,
        private router: Router,
        public settingsService: SettingsService) { }


    public ngOnInit() {
        this.stream = this.authService.canActivateProtectedRoutes$.subscribe(yes => {
            if (yes){
                 document.getElementById('rs-loader').style.display = "none";
                 if( this.isRedirecting == false ){
                  this.isRedirecting = true;
                  return this.router.navigate(['/home']);
              }
              }
            else{
              document.getElementById('rs-loader').style.display = "none";
              if (window.location.href.indexOf('?postLogout=true') > 0) {
                this.authService.login('/login-callback');
              }
            }
        });
    }

    public ngOnDestroy() {
        this.stream.unsubscribe();
    }
}
