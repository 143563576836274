export const environment = {
  production: true,
  ResourceServer: "https://adminapi-auth-test.rs-solutions.ch/",
  IssuerUri: "https://auth-test.rs-solutions.ch",
  RequireHttps: false,
  Uri: "https://admin-auth-test.rs-solutions.ch",
  defaultTheme: "C",
  version: "3.0.3",
  ClientId: 'IS4-Admin',
  contacts: 'https://contacts-test.rs-solutions.ch',
  rstransferUrl: "https://transfer-test.rs-solutions.ch"
};
